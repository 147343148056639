/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 19-11-2020.
 */
import React from 'react'

import './terms.scss'

import sAndMeLogo from '../assets/sandme-logo.svg'
import trademark from '../assets/RegisteredTM.svg'
import HeaderBtn from '../components/frontpage/HeaderBtn'
import SEO from '../components/seo'
import MobileNavigation from '../components/MobileNavigation'
import Layout from '../components/layout'
import { withBreakpoints } from 'react-breakpoints'
import ContactForm from '../components/contact/ContactForm'
import SubpageNavigation from '../components/SubpageNavigation'

const contact = (props) => {
  return (
    <Layout>
      <SEO title="Studies&me" />

      <div className="container section-margin" style={{ minHeight: '100%' }}>
        <div className="row" style={{}}>
          <div className="col-12 col-md-8 mx-auto ">
            <h4 className="mb-4 mt-4">Let’s talk</h4>
            <p>
              Are you a sponsor, physician or patient? Or perhaps you are an investor, researcher or member of the press
              looking for more information. Whatever you need to know, we’re here to help.
            </p>
          </div>

          <div className="col-12 col-md-8 mx-auto ">
            <a href="mailto:hello@studiesandme.com">
              <u>Send us a mail</u>
            </a>
          </div>
        </div>

        {/*<ContactForm></ContactForm>*/}
      </div>
    </Layout>
  )
}

export default withBreakpoints(contact)
